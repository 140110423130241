<template>
  <!-- SmartTag creator -->
  <b-taglist attached aria-controls="contentIdForA11y1">
    <b-tag
      v-for="({ type, value }, index) in orderedTags"
      :key="index"
      :type="type"
      :size="size"
    >
      <div v-if="type == 'is-date'">
        {{ formatDate(value) }}
      </div>
      <div v-else>
        {{ value }}
      </div>
    </b-tag>
  </b-taglist>

  <!-- Stand in Loading SmartTag 
    <b-skeleton v-if="isLoading" width="20rem" size="is-large"></b-skeleton>-->
</template>

<script>
import DateTime from "luxon/src/datetime";

export default {
  name: "SmartTag",

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    tags: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },

    size: {
      type: String,
      default: "is-medium",
      required: false,
      validator: function (value) {
        // The value must match one of these strings
        return ["is-small", "is-medium", "is-large"].indexOf(value) !== -1;
      },
    },
  },

  data() {
    return {
      // Order to display tag types.
      typeOrder: [
        "is-name",
        "is-project",
        "is-role",
        "is-task",
        "is-skill",
        "is-date",
      ],
    };
  },

  methods: {
    formatDate(ISODateString) {
      if (ISODateString) {
        let date = DateTime.fromISO(ISODateString);

        if (date > DateTime.now().plus({ years: 1 })) {
          // If date is over 1 year away use format to month & year.
          return DateTime.fromISO(ISODateString).toFormat("MMM yyyy");
        } else {
          // set default date format to month & day.
          return DateTime.fromISO(ISODateString).toFormat("MMM dd");
        }
      } else {
        return "";
      }
    },
  },

  computed: {
    orderedTags() {
      var output = [];
      for (let type of this.typeOrder) {
        let sorted = this.tags.filter(function (obj) {
          return obj.type === type;
        });
        Array.prototype.push.apply(output, sorted);
      }
      return output;
    },
  },
};
</script>
